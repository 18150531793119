<template>
  <CRow>
    <CCol col>
      <CCard>
        <CCardHeader>
          <CRow class="justify-content-between px-3">
            <div class="d-flex">
              <CButtonGroup size="sm">
                <CButton
                  id="0"
                  :pressed="typeProvider.type == 0"
                  variant="outline"
                  color="info"
                  @click="doSelectProviderType"
                  >Pending</CButton
                >
                <CButton
                  id="1"
                  :pressed="typeProvider.type == 1"
                  variant="outline"
                  color="primary"
                  @click="doSelectProviderType"
                  >Others</CButton
                >
              </CButtonGroup>
            </div>

            <div class="d-flex">
              <CSelect
                style="width: 180px"
                class="mx-2 my-0"
                horizontal
                label="Type"
                :value.sync="search.type"
                :options="search.options"
                @change="searchChangeType"
              />
              <CInput
                style="width: 400px"
                class="my-0 px-0"
                :placeholder="`Search ${search.placeholder}`"
                :value.sync="search.value"
              >
                <template #append>
                  <CButton color="primary" @click="doApplyFilter"
                    >Search</CButton
                  >
                  <CButton
                    class="ml-2"
                    size="sm"
                    color="info"
                    variant="outline"
                    @click="isShowFilter = !isShowFilter"
                  >
                    <CIcon
                      :name="isShowFilter ? 'cil-x' : 'cil-arrow-bottom'"
                    />
                    Filter</CButton
                  >
                </template>
              </CInput>
            </div>
          </CRow>
        </CCardHeader>
        <CCardBody>
          <CCollapse :show="isShowFilter">
            <CCard class="p-3">
              <CRow class="justify-content-between mb-3">
                <h5 class="mx-3">Filter</h5>
                <CButton
                  class="ml-auto mx-3"
                  variant="outline"
                  color="info"
                  @click="doResetFilter"
                  >Reset</CButton
                >
                <CButton class="mx-3" color="info" @click="doApplyFilter"
                  >Apply</CButton
                >
              </CRow>
              <CRow class="justify-content-start">
                <CCol>
                  <treeselect
                    v-model="filter.status"
                    :multiple="true"
                    :options="filterData.status"
                    placeholder="Select status"
                  />
                </CCol>
                <CCol>
                  <treeselect
                    v-model="filter.business_type"
                    :multiple="true"
                    :options="filterData.business_type"
                    placeholder="BusinessType"
                  />
                </CCol>
                <CCol>
                  <treeselect
                    v-model="filter.categories"
                    :multiple="true"
                    :options="filterData.categories"
                    placeholder="Select category"
                  />
                </CCol>
                <CCol>
                  <treeselect
                    v-model="filter.rating"
                    :multiple="true"
                    :options="filterData.rating"
                    placeholder="Rating"
                  />
                </CCol>
                <CCol>
                  <date-picker
                    class="col-12"
                    v-model="filter.dateRangeSelected"
                    range
                    format="YYYY-MM-DD"
                    placeholder="Created date"
                  ></date-picker>
                </CCol>
              </CRow>
            </CCard>
          </CCollapse>
          <CDataTable
            hover
            striped
            border
            sorter
            :loading="loading"
            :items="providers"
            :fields="fields"
            :items-per-page="25"
            :active-page="activePage"
            :pagination="{ doubleArrows: false, align: 'center' }"
            @page-change="pageChange"
          >
            <td slot="id" slot-scope="{ item }">
              <CLink :href="'#/sp/' + item.id">
                {{ item.id }}
              </CLink>
            </td>

            <td slot="stripe" slot-scope="{ item }">
              <span
                v-if="
                  item.provider_setting &&
                  item.provider_setting.stripe_account_id
                "
              >
                <CLink
                  :href="
                    env.stripeURL +
                    '/connect/accounts/' +
                    item.provider_setting.stripe_account_id
                  "
                  target="_blank"
                >
                  {{ item.provider_setting.stripe_account_id }}
                </CLink>
              </span>
              <span v-else>-</span>
            </td>
            <td slot="categories" slot-scope="{ item }">
              <template v-for="category in item.categories">
                <CBadge :color="`success`" :key="category.id" class="mr-1">
                  {{ category.name }}
                </CBadge>
              </template>
            </td>
            <td slot="status" slot-scope="{ item }">
              <CBadge :color="getBadge(item.status)">
                {{ item.status }}
              </CBadge>
            </td>
            <td slot="owner_name" slot-scope="{ item }">
              {{ item.owner.name ? item.owner.name : '-' }}
            </td>
            <td slot="owner_email" slot-scope="{ item }">
              {{ item.owner.email }}
            </td>
            <td slot="owner_phone" slot-scope="{ item }">
              {{ item.owner.phone_number ? item.owner.phone_number : '-' }}
            </td>

            <td slot="last_login" slot-scope="{ item }">
              {{ moment(item.owner.last_login).fromNow() }}
            </td>
            <td slot="created_at" slot-scope="{ item }">
              {{ item.created_at | moment(datetimeFormat) }}
            </td>
            <td slot="booking_expiry" slot-scope="{ item }">
              {{ item.num_expired_booking }}
            </td>
            <td slot="cancellation" slot-scope="{ item }">
              {{ item.num_cancelled_booking }}
            </td>
          </CDataTable>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import { mapGetters } from 'vuex';
import {
  ACTION_FETCH_PROVIDERS,
  ACTION_FETCH_CATEGORIES,
} from '@/store/actions.type';
import { ProviderBadge } from '@/utils/utils';
import { Common } from '@/constants';
import env from '@/constants/env';
import moment from 'moment';

export default {
  name: 'SPApplications',
  data() {
    return {
      env,
      loading: true,
      datetimeFormat: Common.datetimeFormat,
      fields: [
        { key: 'status' },
        {
          key: 'id',
          _style: 'width: 20px',
        },
        { key: 'name', label: 'Alias', _classes: 'font-weight-bold' },
        { key: 'business_name', label: 'BusinessName' },
        { key: 'type', label: 'BusinessType' },
        { key: 'stripe', label: 'Stripe' },
        { key: 'categories', label: 'Categories' },
        { key: 'owner_name', label: 'Name' },
        { key: 'owner_email', label: 'Email' },
        { key: 'owner_phone', label: 'PhoneNumber' },
        { key: 'last_login', label: 'LastLogin' },
        { key: 'created_at', label: 'CreatedDate' },
        { key: 'rate', label: 'Rating' },
        { key: 'booking_expiry', label: 'BookingExpiry' },
        { key: 'cancellation', label: 'Cancellation' },
      ],
      activePage: 1,
      isShowFilter: false,
      search: {
        type: 'alias',
        value: '',
        placeholder: 'Alias',
        options: [
          { value: 'alias', label: 'Alias' },
          { value: 'id', label: 'Id' },
          { value: 'name', label: 'Name' },
          { value: 'email', label: 'Email' },
          { value: 'phone_number', label: 'Phone' },
        ],
      },
      filter: {
        status: null,
        categories: null,
        dateRangeSelected: null,
        business_type: null,
        rating: null,
      },
      filterData: {
        status: [
          { id: 'active', label: 'Active' },
          { id: 'pending', label: 'Pending' },
          { id: 'approved', label: 'Approved' },
          { id: 'rejected', label: 'Rejected' },
          { id: 'banned', label: 'Banned' },
          { id: 'suspended', label: 'Suspended' },
        ],
        categories: [],
        business_type: [
          { id: 'company', label: 'Company' },
          { id: 'freelancer', label: 'Freelancer' },
        ],
        rating: [
          { id: 0, label: '0' },
          { id: 1, label: '1' },
          { id: 2, label: '2' },
          { id: 3, label: '3' },
          { id: 4, label: '4' },
        ],
      },
      typeProvider: {
        type: 0,
      },
    };
  },
  mounted() {
    this.doApplyFilter();

    this.$store.dispatch(ACTION_FETCH_CATEGORIES, {}).then(() => {
      this.filterData.categories = this.categories.map((x) => {
        return { id: x.id, label: x.name };
      });
    });
  },
  computed: {
    ...mapGetters(['providers', 'categories']),
  },
  watch: {
    $route: {
      immediate: true,
      handler(route) {
        if (route.query && route.query.page) {
          this.activePage = Number(route.query.page);
        }
      },
    },
  },
  methods: {
    getBadge(status) {
      return ProviderBadge(status);
    },

    pageChange(val) {
      this.$router.push({ query: { page: val } });
    },
    searchChangeType() {
      this.search.placeholder = this.search.options.find(
        (x) => x.value == this.search.type
      ).label;
      this.search.value = '';
    },
    doApplyFilter() {
      let query = {};

      if (this.typeProvider.type == 0) {
        query = {
          ...query,
          status: ['pending'],
        };
      }
      if (this.typeProvider.type == 1) {
        query = {
          ...query,
          status: ['approved', 'rejected'],
        };
      }

      if (this.search.value?.length > 0) {
        switch (this.search.type) {
          case 'alias': {
            query = { name: this.search.value };
            break;
          }
          case 'id': {
            query = { id: this.search.value };
            break;
          }
          case 'name': {
            query = { owner_name: this.search.value };
            break;
          }
          case 'email': {
            query = { owner_email: this.search.value };
            break;
          }
          case 'phone_number': {
            query = { owner_phone: this.search.value };
            break;
          }
        }
      }

      if (this.filter.categories?.length > 0) {
        query = {
          ...query,
          category_id: this.filter.categories,
        };
      }
      if (this.filter.business_type?.length > 0) {
        query = {
          ...query,
          business_type: this.filter.business_type,
        };
      }
      if (this.filter.rating?.length > 0) {
        query = {
          ...query,
          rating: this.filter.rating,
        };
      }

      if (this.filter.status?.length > 0) {
        query = {
          ...query,
          status:
            this.typeProvider.type == 0
              ? [...this.filter.status, 'pending']
              : this.filter.status,
        };
      }

      if (
        this.filter.dateRangeSelected?.length > 0 &&
        this.filter.dateRangeSelected[0] &&
        this.filter.dateRangeSelected[1]
      ) {
        let fromTo = {
          from: moment(this.filter.dateRangeSelected[0])
            .startOf('day')
            .format('YYYY-MM-DD'),
          to: moment(this.filter.dateRangeSelected[1])
            .endOf('day')
            .format('YYYY-MM-DD'),
        };
        query = {
          ...query,
          ...fromTo,
        };
      }
      this.loading = true;
      this.$store.dispatch(ACTION_FETCH_PROVIDERS, query).then(() => {
        this.loading = false;
      });
    },
    doResetFilter() {
      this.filter.status = null;
      this.filter.categories = null;
      this.filter.dateRangeSelected = null;
      this.filter.business_type = null;
      this.filter.rating = null;

      this.doApplyFilter();
    },
    doSelectProviderType(e) {
      this.typeProvider.type = parseInt(e.target.id);
      this.doApplyFilter();
    },
  },
};
</script>
